// middleware/abort-navigation.ts
export default defineNuxtRouteMiddleware((to, from) => {
    // Function to handle keydown events
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        // Abort navigation when Escape is pressed
        return abortNavigation('Navigation aborted by user.');
      }
    };
  
    // Attach event listener
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on route change
    onBeforeUnmount(() => {
      window.removeEventListener('keydown', handleKeyDown);
    });
  });